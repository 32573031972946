<template>
	<div class="plans">
		<h1>{{ title }}</h1>

		<template v-if="periods && periods.length">

			<v-form @submit.prevent=""
					@keyup.native.enter=""
					v-model="isFormValid">
				<v-row class="mt-4">
					<v-col cols="12" md="2">
						<v-select v-model="form.periodId"
								  :items="periodItems"
								  :readonly="id?true:false"
								  @change="onChangePeriod"
								  label="Период" outlined dense hide-details>

							<template v-slot:item="{active, item, attrs, on}">
								<v-list-item v-on="on" v-bind="attrs" #default="{ active }" ripple>
									<v-list-item-content>
										<v-list-item-title>
											{{ item.text }}
										</v-list-item-title>
									</v-list-item-content>
									<v-list-item-action>
										<!--										<v-icon v-if="item.withAllPlansConfirmed" small :color="colorStatusConfirmed"
																						title="Все утверждено">mdi-check
																				</v-icon>-->
										<v-icon v-if="item.withPlans" small title="Есть планы">
											mdi-check
										</v-icon>
									</v-list-item-action>
								</v-list-item>
							</template>

						</v-select>
					</v-col>
					<v-col cols="12" md="2">
						<v-select v-model="form.channelId"
								  :items="channelItems"
								  :readonly="id?true:false"
								  @change="onChangeChannel"
								  label="Канал" outlined dense hide-details>

							<template v-slot:item="{active, item, attrs, on}">
								<v-list-item v-on="on" v-bind="attrs" #default="{ active }" ripple>
									<v-list-item-content>
										<v-list-item-title>
											{{ item.text }}
										</v-list-item-title>
									</v-list-item-content>
									<v-list-item-action v-if="item.withPlans">
										<v-icon v-if="item.withPlans" small title="Есть планы">
											mdi-check
										</v-icon>
										<!--										<v-icon v-if="item.confirmedAt" small :color="channelStatusColor(item)"
																						title="План утвержден">mdi-check
																				</v-icon>
																				<v-icon v-else-if="item.submittedAt" small :color="channelStatusColor(item)"
																						title="План на утверждении">mdi-magnify
																				</v-icon>
																				<v-icon v-else small :color="channelStatusColor(item)" title="Черновик">
																					mdi-format-list-bulleted-square
																				</v-icon>-->
									</v-list-item-action>
								</v-list-item>
							</template>

						</v-select>
					</v-col>
					<v-col v-if="!existingPlanId" cols="12" md="3">

						<!--:readonly="isSubmitted||isConfirmed"-->
						<v-select v-model="form.agentId"
								  :items="agentItems"
								  @change="onChangeAgent"
								  :readonly="isSubmitted?true:false"
								  label="Контрагент" outlined dense hide-details/>
					</v-col>
					<template v-if="isSubmitted">
						<v-col cols="12" md="5" class="d-flex justify-end">
							<v-chip :color="statusColor" dark>{{ status }}</v-chip>
						</v-col>
					</template>
					<template v-else-if="existingPlanId">
						<v-col cols="12" md="8" class="d-flex align-center">
							<v-btn color="red"
								   dark
								   @click="$router.push({name:'plan', params:{id: existingPlanId}})">Такой план существует, нажмите для просмотра
							</v-btn>
						</v-col>
					</template>
					<template v-else>
						<v-col cols="12" md="3">
							<v-btn color="primary" block @click="save(false)">Сохранить черновик</v-btn>
						</v-col>
						<v-col cols="12" md="2">
							<v-btn block @click="isDialogSubmit=true" :disabled="!hasAmounts">Отправить</v-btn>
						</v-col>
					</template>
				</v-row>

				<order-content v-if="isSubmitted"
							   :products="visibleProducts"
							   :order-items="initialItems"
							   :tree="tree"
							   :name-prop="localizedNameProp"/>
				<order-tables v-else-if="!existingPlanId"
							  :products="visibleProducts"
							  :tree="tree"
							  :initial-items="initialItems"
							  :readonly="isSubmitted||isConfirmed"
							  :confirmed="isConfirmed"
							  :name-prop="localizedNameProp"
							  @change="onChangeAmounts"/>

				<v-row v-if="!existingPlanId && !isSubmitted" class="mt-4">
					<v-col cols="12" md="3" offset-md="7">
						<v-btn color="primary" block @click="save(false)">Сохранить черновик</v-btn>
					</v-col>
					<v-col cols="12" md="2">
						<v-btn block @click="isDialogSubmit=true" :disabled="!hasAmounts">Отправить</v-btn>
					</v-col>
				</v-row>
			</v-form>

		</template>
		<v-row v-else class="mt-4">
			<v-col cols="12">
				<p>К сожалению, планирвание пока недоуступно...</p>
			</v-col>
		</v-row>


		<v-dialog
				v-model="isDialogChanges"
				width="500"
		>
			<v-card>
				<v-card-title>
					План изменен
				</v-card-title>

				<v-card-text>
					Вы не сохранили изменения в плане "{{ periodName }}", они будут утеряны. Продолжить?
				</v-card-text>

				<v-divider/>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
							color="primary"
							text
							@click="cancelChangeRoute"
					>
						Нет
					</v-btn>
					<v-btn
							color="primary"
							text
							@click="changeRoute"
					>
						Да
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog
				v-model="isDialogSubmit"
				width="500"
		>
			<v-card>
				<v-card-title>
					Отправляем план?
				</v-card-title>

				<v-card-text>
					Отправить план "{{ periodName }} {{ channelName }}" на утверждение? С этого момента внести изменения
					в план будет
					невозможно. Продолжить?
				</v-card-text>

				<v-divider/>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
							color="primary"
							text
							@click="isDialogSubmit=false"
					>
						Нет
					</v-btn>
					<v-btn
							color="primary"
							text
							@click="submit"
					>
						Да
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog
				v-model="isDialogSubmitConfirm"
				width="500"
		>
			<v-card>
				<v-card-title>
					План отправлен
				</v-card-title>

				<v-card-text>
					План "{{ periodName }} {{ channelName }}" отправлен на утверждение. Пожалуйста, ожидайте изменения
					статуса в разделе
					Планирование.
				</v-card-text>

				<v-divider/>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
							color="primary"
							text
							@click="isDialogSubmitConfirm=false"
					>
						Отлично
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-snackbar
				v-model="isSnackSaved"
				color="green"
		>
			Черновик плана сохранен!

			<template v-slot:action="{ attrs }">
				<v-btn
						text
						v-bind="attrs"
						@click="isSnackSaved = false"
				>
					Ок
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>

<script>
	import OrderTables from "./elements/order-tables";
	import OrderContent from "./elements/order-content";
	import Vue from "vue";
	import {date2dmy} from "../utils/date";
	import Config from "@/config";

	export default {
		name: "plans-edit",
		components: {OrderTables, OrderContent},
		props: {
			id: {
				type: String | Number
			}
		},
		data: () => ({
			form: {
				periodId: null,
				channelId: null,
				agentId: null,
			},
			loading: false,
			isFormValid: true,
			existingPlanId: null,
			hasChanges: false,
			isDialogChanges: false,
			isDialogSubmit: false,
			isDialogSubmitConfirm: false,
			isSnackSaved: false
		}),
		watch: {
			/*plan: {
				immediate: true,
				handler() {
					this.form.periodId = this.period?.ID || (this.periods ? this.periods[0].ID : null);
					this.form.channelId = this.channel?.ID;
					this.form.agentId = this.plan?.AGENT_ID || (this.user.agents ? this.user.agents[0].ID : null);
				}
			},*/
			/*sessionAgentId() {
				this.form.agentId = this.sessionAgentId;
			},*/
		},
		computed: {
			user() {
				return this.$store.state.session?.user;
			},
			periods() {
				return this.$store.state.page?.PlanController?.periods;
			},
			periodItems() {
				return this.periods?.map(el => {
					return {
						text: el.NAME,
						value: el.ID,
						withPlans: el.withPlans,
						withAllPlansConfirmed: el.withAllPlansConfirmed,
					}
				});
			},
			channels() {
				return this.$store.state.page?.PlanController?.channels;
			},
			channelItems() {
				return this.channels?.map(el => {
					return {
						text: el.NAME,
						value: el.ID,
						withPlans: el.withPlans,
						status: el.status,
						confirmedAt: el.confirmedAt,
						submittedAt: el.submittedAt
					}
				});
			},
			agentItems() {
				return this.user.agents?.map(el => ({
					text: el.NAME,
					value: el.ID
				}));
			},
			period() {
				if ( this.form.periodId ) return this.periods.find(el=>el.ID === this.form.periodId);
				return this.$store.state.page?.PlanController?.period;
			},
			periodName() {
				return this.period?.NAME;
			},
			channelName() {
				return this.channel?.NAME;
			},
			agent() {
				return this.user.agents.find(el => el.ID === this.form.agentId);
			},
			sections() {
				return this.$store.state.page?.PlanController?.sections;
			},
			title() {
				if (this.plan) return "План #" + this.plan.ID
					+ (this.plan.SUBMIT_DATE ? " от " + this.plan.SUBMIT_DATE : "");
				else return "Создание плана на " + this.periodName;
			},
			tree() {
				return this.$store.state.page?.PlanController?.tree;
			},
			products() {
				return this.$store.state.page?.PlanController?.products;
			},
			visibleProducts() {
				// если отправлено, показываем только отправленные
				// иначе - фильтруем по агенту
				if (this.isSubmitted) return this.products.filter(p => this.initialItems?.some(el => el.XML_ID === p.XML_ID));
				return this.products.filter(p => this.agent?.JSON_PRODUCTS?.some(xmlId => xmlId === p.XML_ID));
			},
			plan() {
				return this.$store.state.page?.PlanController?.plan;
			},
			channel() {
				return this.channels.find(el => el.ID === this.form.channelId);
			},
			localizedNameProp() {
				return this.channel?.LOCALIZED_NAME;
			},
			statuses() {
				return this.$store.state.page?.PlanController?.statuses;
			},
			isCancelled() {
				return !!this.plan?.CANCEL_DATE;
			},
			isConfirmed() {
				return !!this.plan?.CONFIRM_DATE;
			},
			isSubmitted() {
				return !!this.plan?.SUBMIT_DATE;
			},
			isExported() {
				return !!this.plan?.EXPORT_DATE;
			},
			status() {
				if (this.isCancelled) return "План отменен";
				if (this.isConfirmed) return "План утвержден";
				if (this.isExported) return "План на утверждении";
				if (this.isSubmitted) return "Скоро будет отправлен";
			},
			statusColor() {
				return this.statuses?.find(el => el.ID === this.plan?.STATUS)?.COLOR;
			},
			colorStatusConfirmed() {
				return Config.COLOR_STATUS_CONFIRMED;
			},
			initialItems() {
				return this.plan?.ITEMS_JSON || [];
			},
			hasAmounts() {
				return this.visibleProducts.some(el=>el.amount>0);
			},
			submitForm() {
				return {
					id: this.plan?.ID,
					periodId: this.form.periodId,
					channelId: this.form.channelId,
					agentId: this.form.agentId,
					items: this.visibleProducts?.map(item => {
						return {
							XML_ID: item.XML_ID,
							amount: item.amount,
						};
					})
				};
			}
		},
		methods: {
			channelStatusColor(channel) {
				return this.channelColorForStatus(channel?.status);
			},
			channelColorForStatus(status) {
				return this.statuses?.find(el => el.ID === status)?.COLOR;
			},
			onChangePeriod() {
				/*if (this.hasChanges) {
					this.isDialogChanges = true;
				} else this.changeRoute();*/
				this.checkPlanExists();
			},
			onChangeChannel() {
				/*if (this.hasChanges) {
					this.isDialogChanges = true;
				} else this.changeRoute();*/
				this.checkPlanExists();
			},
			onChangeAgent(item) {
				//this.form.agentId = item.value;
			},
			onChangeAmounts() {
				this.hasChanges = true;
			},
			checkPlanExists() {
				return this.$store.dispatch('post', {
					action: 'PlanValidationController',
					params: {
						periodId: this.form.periodId,
						channelId: this.form.channelId,
					}
				}).then((res) => {
					//this.$router.push({name: "orders"});
					this.existingPlanId = null;
				}).catch((res) => {
					this.existingPlanId = res.error;
				}).finally(() => {
				});
			},
			changeRoute() {
				this.isDialogChanges = false;

				this.$router.push({
					name: "plan",
					params: {
						periodId: this.form.periodId,
						channelId: this.form.channelId,
					}
				})
			},
			cancelChangeRoute() {
				this.form.periodId = this.period?.ID;
				this.form.channelId = this.channel?.ID;
				this.isDialogChanges = false;
			},
			save(withSubmit = false) {
				this.loading = true;
				this.error = null;

				const params = this.submitForm || {};
				params.withSubmit = withSubmit;

				//console.log("SUBMIT: ", params);

				return this.$store.dispatch('post', {
					action: 'PlanController',
					params
				}).then((res) => {
					//this.$router.push({name: "orders"});

					if (this.$route.name !== "plan"
						&& res.page?.PlanController?.plan?.PERIOD_ID
						&& res.page?.PlanController?.plan?.CHANNEL_ID) {
						// go to saved plan page
						this.$router.push({
							name: "plan",
							params: {
								id: res.page?.PlanController?.plan?.ID,
							}
						});
					} else if (res?.page?.PlanController?.periods && res?.page?.PlanController?.channels) {
						Vue.set(this.$store.state.page.PlanController, "periods", res.page.PlanController.periods);
						Vue.set(this.$store.state.page.PlanController, "channels", res.page.PlanController.channels);

						// update plan from BE
						Vue.set(this.$store.state.page.PlanController, "plan", res.page?.PlanController?.plan);

						if (withSubmit) {
							//Vue.set(this.plan, "SUBMIT_DATE", date2dmy(new Date()));
							//Vue.set(this.plan, "STATUS", "На утверждении");
							this.isDialogSubmitConfirm = true;
						} else this.isSnackSaved = true;

						this.hasChanges = false;
					}
				}).catch((error) => {
					this.error = error ? error : 'Не получилось...';
				}).finally(() => {
					this.loading = false;
					this.isDialogSubmit = false;
				});
			},
			submit() {
				this.save(true);
			}
		},
		created() {
			// this is called every visit to route order-edit (not only once)

			if (this.plan) {
				this.form.periodId = this.plan.PERIOD_ID;
				this.form.channelId = this.plan.CHANNEL_ID;
				this.form.agentId = this.plan.AGENT_ID;
			} else {
				this.form.periodId = this.period?.ID || (this.periods ? this.periods[0].ID : null);
				this.form.channelId = this.channel?.ID || (this.channels ? this.channels[0].ID : null);
				this.form.agentId = this.user.agents ? this.user.agents[0].ID : null;
				this.existingPlanId = this.$store.state.page.PlanController.existingPlan?.ID;
			}
		}
	}
</script>

<style lang="scss">
</style>